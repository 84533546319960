// VARIABLES DEVARIA

/* variables.module.scss */

// Cor primaria 1 - Normalmente cor clara #fff

// Cor primaria 2 - Normalmente cor escura #2C5364

// Cor primaria 3 - Normalmente cor dominante #FBB03B

:root {

  //Moldura foto perfil

  --primary-color-1: #FFFFFF; //Cor primaria 1

  --primary-color-1-opacity: #FFFFFF80; //Cor primaria 1 com opacidade

  --primary-color-2: #1A97A6; //Cor primaria 2

  --primary-color-2-opacity: #1A97A680; //Cor primaria 2 com opacidade

  //Ícones do menu suspenso

  --primary-color-3: #1A97A6; //Cor primaria 3

  --primary-color-3-opacity: #1A97A680; //Cor primaria 3 com opacidade

  --secondary-color-1: #1A97A6; //Cor segundaria

  //Hover ícone usuário no menu

  --color-1: var(--primary-color-2);

  --color-1-opacity-5: var(--primary-color-2-opacity);

  --color-2: #F0F5FF;

  --color-2-opacity-5: #F0F5FF80;

  --color-3: var(--primary-color-2);

  --color-3-opacity-5: var(--primary-color-2-opacity);

  --color-3-opacity-35: var(--primary-color-2-opacity);

  //Bordas perfil

  //Letras perfil

  //Botão recolher chat

  --color-4: #1A97A6;

  --color-4-opacity-25: #0E0A3540;

  --color-4-opacity-1: #0E0A351a;

  //Porcentagem do treinamento

  //Barra de XP na moldura da foto do perfil

  //Botões selecionados no Admin

  //Nome usuário no perfil

  //Moldura e texto do XP

  --color-5: #1A97A6;

  --color-5-opacity-5: var(--primary-color-3-opacity);

  //Botão "Ir para treinamentos" e strong do perfil

  --color-6: var(--primary-color-3);

  //Botão desabilitado filtro admin

  --color-7: #dcdcdc;

  //Background Minhas Conquistas

  --color-8: #FFFFFF;

  --color-9: #d72912;

  --background-color-dark-primary: var(--primary-color-2);

  --background-color-light-primary: #FFFFFF;

  --detail-color-light-2: var(--primary-color-3);

  --detail-color-light-3: #a191ff;

  --background-login-detail: var(--primary-color-2);

  --progress-color-1: #3bd42d;

  --progress-color-2: #32a829;

  --progress-color-3: #2a7a25;

  --progress-color-4: #204d1f;

  --progress-color-5: #2d3531;

  --progress-color-6: #3f3f3f;

  // New Colors 2023

  --background-tertiary: #fff;

  --background-tertiary-light: #FFFFFF;

  --background-gradient-tertiary: linear-gradient(to top, var(--background-tertiary), var(--background-tertiary));

  --devaria-secondary-color: #a7fae9;

  --devaria-border-color: #3C3C3B;

  --devaria-skeleton-color: #333333;

  --transparencia-1: rgba(255, 255, 255, 0.1);

  --player-template-color: #000;

}

//Background recuperar senha

//Botões /treinamentos

$primary-color-1: #FFFFFF; //Cor primaria 1

//Link tremos de uso

//Botão adicionar treinamento (saiba mais)

//Botão exibir selecionado painel admin

//Borda recuperar senha

$primary-color-2: #1A97A6; //Cor primaria 2

//Barra de pesquisa

//Strong tela treinamentos e carreiras

//Texto "Você está assistindo" na aula

$primary-color-3: #1A97A6; //Cor primaria 3

//Contorno menu usuário

//Aula selecionada

//Botão entrar

$secondary-color-1: #1A97A6; //Cor segundaria

//Texto treinamentos e carreiras

$detail-color-light-1: #3C3C3B;

//Botão adquirir treinamento

//Textos, ícones, links e botões das telas de login, cadastro e esqueceu a senha

$detail-color-light-2: $primary-color-3;

//Título carreiras

//Título treinamentos

$detail-color-light-3: $primary-color-2;

//Texto "Adquirir" treinamento

$detail-color-light-4: #433376;

$detail-color-light-5: #F2F2F2;

$detail-color-light-6: #D5EDD2;

$text-color-dark: #1A97A6;

$text-color-light: $primary-color-1;

$primary-color-mais1code: #1A97A6;

$secondary-color-mais1code: #414141;

$aside-background-primary: #fff;

$aside-color-primary: #1A97A6;

$aside-background-selected-primary: #1A97A6;

$aside-color-selected-primary: #FFFFFF;

$footer-color-primary: #0E0A35;

$footer-color-secondary: $primary-color-2;

$footer-color-tertiary: $detail-color-light-1;

$footer-color-quartiary: $primary-color-2;

$footer-color-quintiary: #0E0A35;

$background-modal-tela-assistir: #180A35;

$background-tooltip: #180A35;

$dot-slider-secondary: #A7FAE9;

$backgound-status-trasactions-primary: #d8b1b13b;

$backgound-status-trasactions-secondary: #6c58ff3b;

$backgound-status-trasactions-tertiary: #f2c84c12;

$status-trasactions-primary: #D42D2D;

$status-trasactions-secondary: #6C58FF;

//Background painel Admin

$background-aside-admin: #FFFFFF;

//Texto carregando

//Botões e labels painel admin

$background-color-dark-primary: #3C3C3B;

//Texto botão saiba mais treinamentos

$background-color-dark-secondary:  $primary-color-2;

$background-color-dark-tertiary: $primary-color-2;

$background-color-dark-quartiary: #180A35;

$background-modal-suggestions: #000000d6;

//Texto card treinamentos

//Texto botão "Adicionar à minha lista" treinamentos

//Títulos "Sobre o treinamento" e "Conheça as carreiras que temos preparadas para você"

//Background do Header e textos painel admin

$background-color-light-primary: #1A97A6;

$background-color-light-secondary: #fafafa;

$background-color-light-tertiary: #f0f0f0;

$background-dark-submenu: #220e4394;

$background-light-submenu: #efefef;

$disable-button: #333;

$background-color-badges: #FFFFFF;

$background-color-progressbar-light: #0E0A35;

$background-box-light: #c0baca;

$progress-bar: $primary-color-1;

$background-progress-bar-perfil:  $primary-color-3;

$separator-modules-page-class-video:  $primary-color-3;

$list-inativo-light: #d7291226;

$list-inativo: #d72912;

$skeleton-color-light: #e1e1e1;

$skeleton-highlight-color: #291556;

$skeleton-highlight-color-light: #d0d0d0;

$title-color-devstars: #5c5491;

$button-color-aulas-painel: #b0a4ff;

//Minutos aulas não assistidas

$color-default: #1A1A1A;

$tootle-admin: #3f51b5;

// Meus pedidos

$payment-color: #3bd42d;

$disapproved-color: #eb5757;

$await-payment-color: #f2c94c;

$disabled-color: #77777777;

// Dev Stars heatmap colors

$heatmap-color-primary: rgba(59, 212, 45, 1);

$heatmap-color-secondary: rgba(59, 212, 45, 0.75);

$heatmap-color-tertiary: rgba(59, 212, 45, 0.5);

$heatmap-color-quartiary: rgba(59, 212, 45, 0.25);

// New Colors 2023

//Background aulas

//Background inputs Login, Cadastro, Recuperar Senha

$background-tertiary: #FFFFFF;

//Background chat e lista de módulos das aulas

//Background cards Treinamentos e carreiras

$background-tertiary-light: #1A97A6;

$background-submenu-treinamentos: $background-tertiary;

$background-gradient-tertiary: linear-gradient(to top, $background-tertiary, $background-tertiary);

//Separadores nas telas de login, cadastro e recuperar senha

$devaria-secondary-color: $primary-color-1;

//Bordas cards Treinamentos e carreiras

$devaria-border-color: #3C3C3B;

$devaria-skeleton-color: #333333;

$transparencia-1: rgba(0, 0, 0, 0.1);

//Background do certificado

$background-certificate: #414141;

//Banner conheça seu instrutor na pagina de treinamentos

$meet-instructor-banner: #1A97A6;

$meet-instructor-text-banner: #3C3C3B;

$intructor-name-banner: #3C3C3B;

$background-color-card-modules-training-page: #FFFFFF;

//Background do chat na aula

$container-chat-background: #FFFFFF;

//Background lista de modulos e aulas

$container-modules-list-background: #FFFFFF;

$border-color-aula-container: #1A97A6;

$color-aula-selected: #FFFFFF;

//Texto do card da carreira

$color-text-card-carrer: #FFF;

//Background da caixinha de tour

$tour-background-color: #3C3C3B;

//Texto dos botões no card de treinamento deslogado

$color-text-button-card-tranning: #FFF;

// Texto do botão de adquirir o treinamento na página do treinamento

$color-text-button-purchase-tranning: #fff;

$hover-color-text-button-purchase-tranning: #1A97A6;

// Cores do componente "Button"

$color-text-button-component: #FFFFFF;

$hover-color-text-button-component: #0E0A35;

// Background dos cards na trilha de uma carreira

$background-trail-card-carrer:#3C3C3B;

//Cores para o botão de iniciar treinamento no card

$hover-background-button-tranning-card: #3C3C3B;

$hover-color-button-tranning-card: #FFFFFF;

// Cor do botão que fica no modal de compartilhar conquista

$color-button-modal-view-conquest: #FFFFFF;

$hover-color-button-modal-view-conquest: #3C3C3B;

$color-button-modal-download-certificate: #3C3C3B;

$hover-color-button-modal-download-certificate: #FFFFFF;

// Variaveis para a tela que exibe a conquista do usuário

$background-conquest-card: #3C3C3B;

$color-button-download-certificate: #3C3C3B;

$hover-color-button-download-certificate: #FFFFFF;

$color-button-view-conquest: #FFFFFF;

$hover-color-button-view-conquest: #3C3C3B;

$color-text-card-carrer-is-more: #3C3C3B;

$hover-color-text-card-carrer-is-more: #FFFFFF;

$color-text-card-carrer-is-buy: #FFFFFF;

$hover-color-text-card-carrer-is-buy: #3C3C3B;

$border-color-button-card-carrer-is-buy: #FFFFFF;

// Cores para o modal de desafios de uma aula

$color-text-challenger-modal: #3C3C3B;

$color-text-button-cancel-challenger: #3C3C3B;

$hover-color-text-button-cancel-challenger: #1A97A6;

$border-color-button-cancel-challenger: #3C3C3B;

$hover-border-color-button-cancel-challenger: #1A97A6;

$color-background-modal-challenge-exit: #FFFFFF;

$hover-color-button-finish-challenge: #FFFFFF;

// Cor dos ícones do perfil

$color-icons-profile-home: #1A97A6;

// Cor dos ícones da página do treinamento

$color-icons-training-page: #1A97A6;

// Cor do texto do card dos treinamentos no /treinamentos

$text-color-card-training: #fff;

// Cor inputs descrição aula Admin

$color-admin-input-description: #FFFFFF;

// Cor botões submit Admin

$color-button-submit-admin: #FFFFFF;

:export {

  primaryColor1: $primary-color-1;

  primaryColor2: $primary-color-2;

  primaryColor3: $primary-color-3;

  secondaryColor1: $secondary-color-1;

  detailColorLight1: $detail-color-light-1;

  detailColorLight2: $detail-color-light-2;

  detailColorLight3: $detail-color-light-3;

  detailColorLight4: $detail-color-light-4;

  detailColorLight5: $detail-color-light-5;

  detailColorLight6: $detail-color-light-6;

  colorTextDark: $text-color-dark;

  colorTextLight: $text-color-light;

  colorSeparatorModulesPageClass: $separator-modules-page-class-video;

  dotSliderSecondary: $dot-slider-secondary;

  primaryColorMais1code: $primary-color-mais1code;

  secondaryColorMais1code: $secondary-color-mais1code;

  backgoundStatusTrasactionsPrimary: $backgound-status-trasactions-primary;

  backgoundStatusTrasactionsSecondary: $backgound-status-trasactions-secondary;

  backgoundStatusTrasactionsTertiary: $backgound-status-trasactions-tertiary;

  statusTrasactionsPrimary: $status-trasactions-primary;

  statusTrasactionsSecondary: $status-trasactions-secondary;

  asideBackgroundSelectedPrimary: $aside-background-selected-primary;

  asideColorSelectedPrimary: $aside-color-selected-primary;

  asideBackgroundPrimary: $aside-background-primary;

  asideColorPrimary: $aside-color-primary;

  footerColorPrimary: $footer-color-primary;

  footerColorSecondary: $footer-color-secondary;

  footerColorTertiary: $footer-color-tertiary;

  footerColorQuartiary: $footer-color-quartiary;

  footerColorQuintiary: $footer-color-quintiary;

  backgroundColorDarkPrimary: $background-color-dark-primary;

  backgroundColorDarkSecondary: $background-color-dark-secondary;

  backgroundColorDarkTertiary: $background-color-dark-tertiary;

  backgroundColorDarkQuartiary: $background-color-dark-quartiary;

  backgroundModalTelaAssistir: $background-modal-tela-assistir;

  backgroundTooltip: $background-tooltip;

  backgroundModalSuggestions: $background-modal-suggestions;

  backgroundColorLightPrimary: $background-color-light-primary;

  backgroundColorLightSecondary: $background-color-light-secondary;

  backgroundColorLightTertiary: $background-color-light-tertiary;

  submenuBackgroundDark: $background-dark-submenu;

  submenuBackgroundLight: $background-light-submenu;

  disableButton: $disable-button;

  colorDefault: $color-default;

  hrPrimary: $primary-color-2;

  hrSecondary: $footer-color-quartiary;

  backgroundColorBadges: $background-color-badges;

  backgroundColorProgressbarLight: $background-color-progressbar-light;

  backgroundBoxLight: $background-box-light;

  progressBar: $progress-bar;

  backgroundProgressBarPerfil: $background-progress-bar-perfil;

  listInativoLight: $list-inativo-light;

  listInativo: $list-inativo;

  skeletonColorLight: $skeleton-color-light;

  skeletonHighlightColor: $skeleton-highlight-color;

  skeletonHighlightColorLight: $skeleton-highlight-color-light;

  titleColorDevstars: $title-color-devstars;

  buttonColorAulasPainel: $button-color-aulas-painel;

  toogleAdmin: $tootle-admin;

  paymentColor: $payment-color;

  disapprovedColor: $disapproved-color;

  awaitPaymentColor: $await-payment-color;

  disabledColor: $disabled-color;

  heatamapColorPrimary: $heatmap-color-primary;

  heatamapColorSecondary: $heatmap-color-secondary;

  heatamapColorTertiary: $heatmap-color-tertiary;

  heatamapColorQuartiary: $heatmap-color-quartiary;

  backgroundTertiary: $background-tertiary;

  backgroundTertiaryLight: $background-tertiary-light;

  backgroundGradientTertiary: $background-gradient-tertiary;

  devariaSecondaryColor: $devaria-secondary-color;

  devariaBorderColor: $devaria-border-color;

  devariaSkeletonColor: $devaria-skeleton-color;

  transparencia1: $transparencia-1;

  backgroundCertificate: $background-certificate;

  meetInstructorBanner: $meet-instructor-banner;

  containerChatBackground: $container-chat-background;

  containerModulesListBackground: $container-modules-list-background;

  colorTextCardCarrer: $color-text-card-carrer;

  tourBackgroundColor: $tour-background-color;

  colorTextButtonCardTranning: $color-text-button-card-tranning;

  colorTextButtonPurchaseTranning: $color-text-button-purchase-tranning;

  hoverColorTextButtonPurchaseTranning: $hover-color-text-button-purchase-tranning;

  colorTextButtonComponent: $color-text-button-component;

  hoverColorTextButtonComponent: $hover-color-text-button-component;

  backgroundTrailCardCarrer: $background-trail-card-carrer;

  hoverBackgroundButtonTranningCard: $hover-background-button-tranning-card;

  hoverColorButtonTranningCard: $hover-color-button-tranning-card;

  colorButtonModalViewConquest: $color-button-modal-view-conquest;

  hoverColorButtonModalViewConquest: $hover-color-button-modal-view-conquest;

  colorButtonModalDownloadCertificate: $color-button-modal-download-certificate;

  hoverColorButtonModalDownloadCertificate: $hover-color-button-modal-download-certificate;

  backgroundConquestCard: $background-conquest-card;

  colorButtonDownloadCertificate: $color-button-download-certificate;

  hoverColorButtonDownloadCertificate: $hover-color-button-download-certificate;

  colorButtonViewConquest: $color-button-view-conquest;

  hoverColorButtonViewConquest: $hover-color-button-view-conquest;

  colorTextCardCarrerIsMore: $color-text-card-carrer-is-more;

  hoverColorTextCardCarrerIsMore: $hover-color-text-card-carrer-is-more;

  colorTextCardCarrerIsBuy: $color-text-card-carrer-is-buy;

  hoverColorTextCardCarrerIsBuy: $hover-color-text-card-carrer-is-buy;

  colorTextChallengerModal: $color-text-challenger-modal;

  colorTextButtonCancelChallenger: $color-text-button-cancel-challenger;

  hoverColorTextButtonCancelChallenger: $hover-color-text-button-cancel-challenger;

  borderColorButtonCancelChallenger: $border-color-button-cancel-challenger;

  hoverBorderColorButtonCancelChallenger: $hover-border-color-button-cancel-challenger;

  colorIconsProfileHome: $color-icons-profile-home;

  colorIconsTrainingPage: $color-icons-training-page;

  textColorCardTraining: $text-color-card-training;

  intructorNameBanner: $intructor-name-banner;

  meetInstructorTextBanner: $meet-instructor-text-banner;

  borderColorButtonCardCarrerIsBuy: $border-color-button-card-carrer-is-buy;

  colorBackgroundModalChallengeExit: $color-background-modal-challenge-exit;

  hoverColorButtonFinishChallenge: $hover-color-button-finish-challenge;

  backgroundColorCardModulesTrainingPage: $background-color-card-modules-training-page;

  borderColorAulaContainer: $border-color-aula-container;

  colorAulaSelected: $color-aula-selected;

  backgroundAsideAdmin: $background-aside-admin;

  colorButtonSubmitAdmin: $color-button-submit-admin;

  backgroundSubmenuTreinamentos: $background-submenu-treinamentos;
}
