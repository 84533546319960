//
// Global Config
//

@font-face {
    font-family: "Helvetica Neue LT Std 57 Condensed";
    font-style: normal;
    font-weight: normal;
    src:
        local("Helvetica Neue LT Std 57 Condensed"),
        url("../fonts/HelveticaNeueLTStd-Cn.woff") format("woff");
}

@font-face {
    font-family: "Helvetica Neue LT Std 47 Light Condensed";
    font-style: normal;
    font-weight: normal;
    src:
        local("Helvetica Neue LT Std 47 Light Condensed"),
        url("../fonts/HelveticaNeueLTStd-LtCn.woff") format("woff");
}

@font-face {
    font-family: "Helvetica Neue LT Std 77 Bold Condensed";
    font-style: normal;
    font-weight: normal;
    src:
        local("Helvetica Neue LT Std 77 Bold Condensed"),
        url("../fonts/HelveticaNeueLTStd-BdCn.woff") format("woff");
}

@font-face {
    font-family: "Catesque Bold";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Catesque/catesque-bold.otf") format("opentype");
}

@font-face {
    font-family: "Catesque Italic";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Catesque/catesque-italic.otf") format("opentype");
}

@font-face {
    font-family: "Catesque Light";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Catesque/catesque-light.otf") format("opentype");
}

@font-face {
    font-family: "Catesque Medium";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Catesque/catesque-medium.otf") format("opentype");
}

@font-face {
    font-family: "Catesque Regular";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Catesque/catesque-regular.otf") format("opentype");
}

@font-face {
    font-family: "Biennale Medium";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Biennale/Biennale-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Roboto Medium";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Roboto/Roboto-Medium.ttf") format("opentype");
}

@font-face {
    font-family: "Roboto Regular";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Roboto/Roboto-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "Biennale Regular";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Biennale/Biennale-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Biennale Black";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Biennale/Biennale-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "Biennale Bold";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Biennale/Biennale-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Biennale Heavy";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Biennale/Biennale-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Biennale SemiBold";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Biennale/Biennale-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "Biennale Light";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Biennale/Biennale-Light.otf") format("opentype");
}

@font-face {
    font-family: "Biennale";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Biennale/Biennale-Regular.otf") format("opentype");
}

// Init global functions and mixins
@import "global/init";
@import "../../variables.scss";
// Layout Breakpoints(bootstrap responsive breakpoints)
// Define the minimum and maximum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$kt-media-breakpoints: (
    // Extra small screen / phone
    xs: 0,

    // Small screen / phone
    sm: 576px,

    // Medium screen / tablet
    md: 768px,

    // Large screen / desktop
    lg: 1024px,

    //1024px,
    // Extra large screen / wide desktop
    xl: 1399px
) !default;

// Global rounded border mode
$kt-rounded: true !default;

// Border Radius
$kt-border-radius: 4px !default;

// Core icon codes(lineawesome icons: https://icons8.com/line-awesome)
$kt-action-icons: (
    close: "\f191",
    down: "\f110",
    up: "\f113",
    left: "\f111",
    right: "\f112",
    plus: "\f2c2",
    minus: "\f28e"
) !default;

// Core bold icon codes(lineawesome icons: https://keenthemes.com/metronic/preview/default/components/icons/flaticon.html)
$kt-action-bold-icons: (
    down: "\f1a3",
    up: "\f1a5",
    left: "\f1a4",
    right: "\f19d",
    close: "\f1b2"
) !default;

// Elevate shadow
$kt-elevate-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05) !default;
$kt-elevate-shadow-2: 0px 0px 13px 0px rgba(82, 63, 105, 0.1) !default;

// Dropdown shadow
$kt-dropdown-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !default;

// Custom scrollbar color
$kt-scroll-color: darken(#ebedf2, 6%) !default;

// Transition
$kt-transition: all 0.3s !default;

// Modal Z-index
$kt-modal-zindex: 1050 !default;

// dropdown Z-index
$kt-dropdown-zindex: 95 !default;

// State Color
$kt-state-colors: (
    // Metronic states
    brand:
        (
            base: $background-color-dark-primary,
            inverse: $background-color-light-primary
        ),
    light: (
        base: $color-admin-input-description,
        inverse: #282a3c
    ),
    dark: (
        base: #282a3c,
        inverse: $background-color-light-primary
    ),

    // Bootstrap states
    primary:
        (
            base: $background-color-dark-primary,
            inverse: $background-color-light-primary
        ),
    success: (
        base: #3dff9e,
        //1dc9b7,
        inverse: $background-color-light-primary
    ),
    info: (
        base: #5578eb,
        inverse: $background-color-light-primary
    ),
    warning: (
        base: $primary-color-1,
        inverse: $background-color-light-primary
    ),
    danger: (
        base: #ff7563,
        inverse: $background-color-light-primary
    ),
    shape: (
        base: #646c9a,
        inverse: $background-color-light-primary
    ),
    optionSelected: (
        base: $background-color-dark-primary,
        inverse: $background-color-dark-primary
    ),
    lateralAdmin: (
        base: $background-color-dark-primary,
        inverse: $primary-color-2
    ),
    colorPortilet: (
        base: $background-color-dark-primary,
        inverse: $background-color-light-primary
    ),
    colorProfileOption: (
        base: #0abb8610,
        //1dc9b7,
        inverse: $background-color-light-primary
    ),
    azulLogo: (
        base: #8acbc0,
        //1dc9b7,
        inverse: $background-color-light-primary
    )
) !default;

$kt-state-colors-iniciatives: (
    orange: (
        base: #ffc85c,
        inverse: $background-color-light-primary
    ),
    brown: (
        base: #994834,
        inverse: $background-color-light-primary
    ),
    blue: (
        base: #5b7eb3,
        inverse: $background-color-light-primary
    ),
    green: (
        base: #6a992c,
        inverse: $background-color-light-primary
    ),
    grey: (
        base: #ccc,
        inverse: $background-color-light-primary
    )
);
// Base colors
$kt-base-colors: (
    label: (
        1: #a2a5b9,
        2: #74788d,
        3: #595d6e,
        4: #48465b,
        5: $background-color-light-primary,
        6: $background-color-dark-primary
    ),
    shape: (
        1: #f0f3ff,
        2: #e8ecfa,
        3: #93a2dd,
        4: #646c9a,
        5: #283b0f
    ),
    grey: (
        1: #f7f8fa,
        //#f4f5f8
        2: #ebedf2,
        3: darken(#ebedf2, 3%),
        4: darken(#ebedf2, 6%),
        5: rgb(107, 106, 106)
    )
) !default;

// Social network colors(see: https://brandcolors.net/)
$kt-social-colors: (
    facebook: (
        base: #485a96,
        inverse: $background-color-light-primary
    ),
    google: (
        base: #dc4e41,
        inverse: $background-color-light-primary
    ),
    twitter: (
        base: #4ab3f4,
        inverse: $background-color-light-primary
    ),
    instagram: (
        base: #517fa4,
        inverse: $background-color-light-primary
    ),
    youtube: (
        base: #b31217,
        inverse: $background-color-light-primary
    ),
    linkedin: (
        base: #0077b5,
        inverse: $background-color-light-primary
    ),
    skype: (
        base: #00aff0,
        inverse: $background-color-light-primary
    )
) !default;

// Root Font Settings
$kt-font-families: (
    regular: unquote("Catesque Regular"),
    heading: unquote("Catesque Regular")
) !default;

// Root Font Settings
$kt-font-size: (
    size: (
        desktop: 13px,
        tablet: 12px,
        mobile: 12px
    ),
    weight: 300
) !default;

// General Link Settings
$kt-font-color: (
    text: kt-get($kt-base-colors, label, 6),
    link: (
        default: kt-state-color(brand),
        hover: darken(kt-state-color(brand), 6%)
    )
) !default;

// Portlet settings
$kt-portlet: (
    min-height: (
        default: (
            desktop: 60px,
            mobile: 50px
        ),
        sm: (
            desktop: 50px,
            mobile: 40px
        ),
        lg: (
            desktop: 80px,
            mobile: 60px
        ),
        xl: (
            desktop: 100px,
            mobile: 80px
        )
    ),
    space: (
        desktop: 25px,
        mobile: 15px
    ),
    bottom-space: (
        desktop: 20px,
        mobile: 20px
    ),
    border-color: kt-base-color(grey, 2),
    bg-color: kt-get($kt-state-colors, colorPortilet base),
    shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05)
) !default;

// Page padding
$kt-page-padding: (
    desktop: 25px,
    mobile: 15px
) !default;

// Page container width
$kt-page-container-width: 1380px !default;

// Custom Scroll(Perfect Scrollbar) size
$kt-custom-scroll-size: 4px !default;
